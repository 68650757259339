<template>
  <v-container fluid grid-list-md>
    <v-form>
      <v-layout row wrap>
        <v-flex xs12>
          <h4>Нарачка</h4>
          <table class="details">
            <tr>
              <td>Датум на нарачка</td>
              <td>{{ orderLocal.created | moment('DD.MM.YYYY') }}</td>
            </tr>
            
            <tr>
              <td>&nbsp;</td>
              <td></td>
            </tr>
            <tr>
              <td>Име и презиме</td>
              <td>{{ orderLocal.name }}</td>
            </tr>
            <tr>
              <td>Е-Мајл</td>
              <td>{{ orderLocal.email }}</td>
            </tr>
            <tr>
              <td>Телефон</td>
              <td>{{ orderLocal.tel }}</td>
            </tr>
            <tr>
              <td>Адреса</td>
              <td>{{ orderLocal.address }}</td>
            </tr>
            <tr>
              <td>Место</td>
              <td>{{ orderLocal.city }}</td>
            </tr>
            <tr>
              <td>Забелешка</td>
              <td>{{ orderLocal.description }}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
            </tr>
            <tr>
              <td>ИД на нарачка</td>
              <td>{{ orderLocal.id }}</td>
            </tr>
            <tr v-if="orderLocal.coupon">
              <td>Код за попуст</td>
              <td>{{ orderLocal.coupon.code }} ({{orderLocal.coupon.discount}}%)</td>
            </tr>
            <tr>
              <td>Наплатено</td>
              <td>{{ orderLocal.total }} ден.</td>
            </tr>
            <tr>
              <td>Статус</td>
              <td>{{ orderLocal.sentString }}</td>
            </tr>
          </table>

          <table style="width: 100%; margin-top: 2em;">
            <tr>
              <td>Продукт</td>
              <td>Цена</td>
              <td>Кол.</td>
              <td>Попуст (%)</td>
              <td>Вкупно</td>
            </tr>
            <tr v-for="item in orderLocal.orderItems" :key="item.id">
              <td>{{item.product.name}}</td>
              <td>{{item.price}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.discount}}</td>
              <td>{{item.total}}</td>
            </tr>
          </table>

          <v-radio-group v-model="orderLocal.sent" v-if="order.sent === 0">
            <v-radio label="Испратена" value="1"></v-radio>
            <v-radio label="Сторнирана" value="-1"></v-radio>
          </v-radio-group>
          <v-textarea
            v-if="hasChangedStatus"
            v-model="mailMessage"
            label="Порака"
            hint="Пораката ке биде пратена по мајл"
            auto-grow
            rows="1"
            :counter="255"
            v-validate="'max:255'"
            :error-messages="errors.collect('note')"
            data-vv-name="note"
          ></v-textarea>
        </v-flex>

        <v-flex xs12>
          <v-card-actions>
            <v-btn color="primary" flat @click="close">Откажи</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="save"
              :loading="$store.state.loading"
              :disabled="mailMessage.length<5 || !hasChangedStatus"
            >Запамти</v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: {
    order: {
      required: true,
      default: null
    }
  },

  data() {
    return {
      valid: false,
      orderLocal: Object.assign({}, this.order),
      newStatus: null,
      mailMessage: "",

      hasChangedStatus: false
    };
  }, // data

  methods: {
    /**
     * Save the changes
     *
     */
    save: async function() {
      try {
        let response = await axios.post(
          "/backend/member/admin/order/update/" + this.order.id,
          {
            order: this.orderLocal,
            message: this.mailMessage
          }
        );
        this.$emit("saved", { old: this.order, new: response.data });
        this.$store.commit(
          "showSnackbar",
          "Podatocite za narackata se zapamteni"
        );
      } catch (err) {
        console.log(err);
        this.$store.commit("showSnackbar", err.message);
      }
    },

    /**
     * Emit close signal
     *
     */
    close() {
      this.$emit("close");
    },

    /**
     *
     */
    isValid() {
      return this.valid;
    }
  },

  watch: {
    "orderLocal.sent": function(value) {
      this.hasChangedStatus = true;
      if (value == 1) this.mailMessage = "Нарачката е испратена.";
      if (value == -1)
        this.mailMessage =
          "Нарачката е сторнирана. Средствата во целост ќе ви бидат вратени.";
    }
  }
};
</script>

<style scoped>
table.details td:nth-child(2) {
  font-weight: bold;
}
</style>