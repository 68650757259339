<template>
  <v-dialog v-model="dialog" width="600" :fullscreen="isMobile()? true:false" lazy persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on">Купи</v-btn>
    </template>
    <!-- use v-if do destroy, otherwise coupon validator makes bugs -->
    <cart :product="product" @close="dialog=false" v-if="dialog"></cart>
  </v-dialog>
</template>

<script>
import cart from "@/components/Public/Cart/Cart";
import mobileDetector from '@/Helpers/MobileDetector'
export default {
  /**
   *
   */
  props: {
    /**
     * Product code from product table
     */
    product: {
      required: true
    }
  },

  /**
   * Data
   *
   */
  data() {
    return {
      dialog: false
    };
  }, // data

  /**
   * Methods
   *
   */
  methods: {
    /**
     * Check if the device is mobile or desktop by resulution
     *
     */
    isMobile() {
      return mobileDetector.isMobile();
    }
  },

  /**
   * Watch
   */
  watch: {},

  components: { cart }
};
</script>

<style scoped>
</style>