<template>
  <v-container fluid grid-list-md>
    <v-form>
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            label="Код *"
            v-model="couponLocal.code"
            hint="Кодот на купонот"
            :error-messages="errors.collect('code')"
            v-validate="'required'"
            data-vv-name="code"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <v-text-field
            label="Попуст"
            v-model="couponLocal.discount"
            hint="Попуст на продуктот во %"
            type="number"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="couponLocal.untill"
                label="Валиден до"
                readonly
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="couponLocal.untill" @input="menuDate = false"></v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12>
          <v-switch label="Активен" v-model="couponLocal.active"></v-switch>
        </v-flex>

        <v-flex xs12>
          <v-card-actions>
            <v-btn color="primary" flat @click="close">Откажи</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="hasNotValidField"
              @click="save"
              :loading="$store.state.loading"
            >Запамти</v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: {
    coupon: {
      required: true,
      default: null
    }
  },

  data() {

    // remove time from date string
    let couponLocal = Object.assign({ id: 0 }, this.coupon);
    if (couponLocal.untill)
      couponLocal.untill = couponLocal.untill.substr(0, 10);

    return {
      valid: false,
      couponLocal: couponLocal,
      menuDate: null,
    };
  }, // data

  methods: {
    /**
     * Save the changes
     *
     */
    save: async function() {
      try {
        let response = await axios.post(
          "/backend/member/admin/coupon/update/" + this.couponLocal.id,
          this.couponLocal
        );
        this.$emit("saved", { old: this.coupon, new: response.data });
        this.$store.commit(
          "showSnackbar",
          "Podatocite za podarokot se zapamteni"
        );
      } catch (err) {
        console.log(err);
        this.$store.commit("showSnackbar", err.message);
      }
    },

    /**
     * Emit close signal
     *
     */
    close() {
      this.$emit("close");
    }
  },

  computed: {
    /**
     * Check the form using vee validate,
     * if there are any fields that are not valid.
     *
     * return true if there are not valid fields
     */
    hasNotValidField() {
      return Object.keys(this.fields).some(
        key => this.fields[key].valid == false
      );
    }
  }
};
</script>