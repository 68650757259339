<template>
  <div class="cart-product">
    <table class="cart-product-grid">
      <tr>
        <th class="string">Назив на продуктот</th>
        <th class="string">Количина</th>
        <th class="number">Цена</th>
        <th class="number"> <span class="hidden-xs-only">Попуст</span> (%)</th>
        <th class="number">Вредност</th>
      </tr>
      <tr v-for="product in productsLocal" :key="product.id">
        <td class="product-name">
          {{product.name}}
        </td>
        <td class="product-quantity">
          <v-text-field
            type="number"
            title="Изберете количина."
            :min="1"
            :max="5"
            v-validate="'required|alpha_num|between:1,5'"
            :error-messages="errors.collect(validateName(product))"
            :data-vv-name="validateName(product)"
            v-model="product.quantity"
          ></v-text-field>
        </td>
        <td class="number">{{product.price}}</td>
        <td class="number discount-value">{{product.discount}}</td>
        <td class="number" style="position: relative;">
          {{product.total}}

          <v-btn
            small
            fab
            class="remove-item"
            title="Тргни го продуктот од кошничката."
            @click="removeItem(product)"
            v-if="productsLocal.length>1"
          >x</v-btn>
        </td>
      </tr>
    </table>

    <!-- Add new product button -->
    <v-btn
      small
      fab
      class="add-new-item"
      title="Додади друг продукт."
      @click="$refs.allProducts.showIt()"
    >+</v-btn>
    <all-products ref="allProducts" @add="addNewItem"></all-products>
  </div>
</template>

<script>
import axios from "axios";
import allProducts from "./AllProducts";

export default {
  /**
   * Inject parent validator into this component, so it can be validated
   * within the parent
   */
  inject: {
    $validator: "parentValidator"
  },

  /**
   *
   */
  props: {
    /**
     * Coupon object
     */
    coupon: {
      type: Object,
      required: false
    },

    /**
     * List of products
     */
    products: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    }
  },

  /**
   * Data
   *
   */
  data() {
    return {
      productsLocal: [],
      total: 0
    };
  }, // data

  /**
   *
   */
  methods: {
    validateName(product) {
      return "kolicina-" + product.id;
    },

    /**
     * Recalculate prcies
     */
    process() {
      this.total = 0;

      this.productsLocal.forEach(productLocal => {
        productLocal.discount = this.getDiscount(productLocal);
        productLocal.total =
          (productLocal.price -
            (productLocal.price * this.getDiscount(productLocal)) / 100) *
          productLocal.quantity;

        if (productLocal.quantity < 0) productLocal.quantity = 0;

        this.total += productLocal.total;
      });

      this.$emit("total", { total: this.total, items: this.productsLocal });
    },

    /**
     * Create local structure from product
     */
    productLocalFromProduct(product) {
      return {
        id: product.id,
        idx: product.idx, // max discount
        name: product.name,
        quantity: 1,
        price: product.price,
        discountRegular: product.discount,
        discount: this.getDiscount(product),
        total: product.price - (product.price * product.discount) / 100
      };
    },

    /**
     * Presmetaj go popustot
     */
    getDiscount(product) {
      // let originalProduct = this.products.find(item => {
      //   return item.id == product.id;
      // });

      //let discount = product.discount;
      let discount = product.discountRegular;
      if (this.coupon && this.coupon.discount > discount)
        discount = this.coupon.discount;
      // idx e maksimalniot popust dozvolen za toj produkt
      if (discount > product.idx) discount = product.idx;

      return discount;
    },

    /**
     * Add additional item
     */
    addNewItem(product) {
      // check if already added
      let existing = this.productsLocal.find(item => {
        return item.id == product.id;
      });
      if (existing) return;

      this.productsLocal.push(this.productLocalFromProduct(product));
      this.process();
    },

    /**
     * Remove item from the list
     */
    removeItem(product) {
      var index = this.productsLocal.findIndex(function(o) {
        return o.id === product.id;
      });
      if (index !== -1) {
        this.productsLocal.splice(index, 1);
        this.process();
      }
    }
  },

  watch: {
    productsLocal: {
      handler: function(newValue, oldValue) {
        this.process();
      },
      deep: true
    },

    /**
     * Coupon added or removed
     */
    coupon() {
      this.process();
    }
  },

  mounted() {
    /**
     * Create local copy of the product and add quantity field
     */
    this.products.forEach(product => {
      this.productsLocal.push(this.productLocalFromProduct(product));
    });
    this.process();
  },

  components: { allProducts }
};
</script>

<style>
.cart-product {
  width: 100%;
  position: relative;
}

.add-new-item {
  height: 25px !important;
  width: 25px !important;
  position: absolute !important;
  left: -9px;
  bottom: -10px;
}

.remove-item {
  height: 18px !important;
  width: 18px !important;
  position: absolute !important;
  right: -29px;
  top: calc(50% - 17px);
}

.remove-item .v-btn__content {
  line-height: 1em;
}

.discount-value {
  color: #1e9e6b;
  font-weight: bold;
}
.cart-product-grid {
  width: 100%;
}

.cart-product-grid th {
  font-size: 12px;
}

.cart-product-grid td {
  font-size: 13px;
}

.cart-product-grid .number {
  text-align: right;
}
.cart-product-grid .string {
  text-align: left;
}

.cart-product-grid .product-name {
  width: 40%;
  padding-right: 5px;
  line-height: 1em;
}

.cart-product-grid .product-quantity {
  width: 100px;
}

.cart-product-grid td {
  vertical-align: middle;
}
</style>