import { render, staticRenderFns } from "./AllProducts.vue?vue&type=template&id=3948b2ed&"
import script from "./AllProducts.vue?vue&type=script&lang=js&"
export * from "./AllProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/data/projects/winix/winix_shop/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileSubTitle } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VProgressLinear } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardText,VDialog,VDivider,VList,VListTile,VListTileContent,VListTileSubTitle,VListTileTitle,VProgressLinear,VSpacer,VToolbar,VToolbarTitle})
