<template>
  <v-app>
    <v-progress-linear class="main-progress" :indeterminate="true" height="4" :active="$store.state.loading "></v-progress-linear>

    <v-toolbar app>
      <v-toolbar-title class="headline text-uppercase">
        <span>WinixMk</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Linkovi koi se dostapni zamo za admin -->
      <v-toolbar-items v-if="user && user.roles.includes('ROLE_ADMIN')">
        <v-btn flat :to="{name: 'Products'}">
          <v-icon left>kitchen</v-icon>Продукти
        </v-btn>
        <v-btn flat :to="{name: 'Orders'}">
          <v-icon left>list</v-icon>Нарачки
        </v-btn>
        <v-btn flat :to="{name: 'Coupons'}">
          <v-icon left>card_giftcard</v-icon>Купони
        </v-btn>
      </v-toolbar-items>

      <!-- Login -->
      <v-toolbar-items v-if="!user">
        <v-btn flat :to="{name: 'Login'}">
          <v-icon left>face</v-icon>Најава
        </v-btn>
      </v-toolbar-items>
      
      <!-- user profilem koga e logiran -->
      <v-toolbar-items v-if="user">
        <v-menu offset-y v-if="user">
          <v-btn flat slot="activator">
            <v-icon left>face</v-icon>
            {{user.name}}
          </v-btn>
          <v-list>
            <!-- Logout item -->
            <v-list-tile @click="$store.dispatch('logout')">
              <v-list-tile-avatar>
                <v-icon>exit_to_app</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-title>Одјави се</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <v-content>
      <!-- 
        :key="$route.fullPath"
        So ova dokolku se promeni parametar vo rutata, se refresira stranata.
        Primer, /admin/zakoni/edit/8302, ako se smeni /admin/zakoni/edit/8303
        togas ce se refresira stranat. bez key, so promena na id-to nema da se refresira stranata
      -->
      <router-view :key="$route.fullPath"/>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {};
  },

  computed: {
    /**
     * Store user changes. needed for logout watch
     */
    user() {
      return this.$store.state.user.user;
    }
  },

  /**
   * Watch for changes
   */
  watch: {
    /**
     * Store user changes
     *
     */
    user(value) {
      // logout user
      if (value === null) this.$router.replace("login");
    }
  }
};
</script>

<style>
.main-progress.v-progress-linear {
    z-index: 99;
    margin-top: 0px !important;
    position: fixed !important;
}
</style>