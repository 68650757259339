<template>
  <div>
    <v-alert type="warning" :value="errorMessage">{{errorMessage}}</v-alert>
    <v-card-text>
      <v-form>
        <v-text-field
          autofocus
          label="Е-Mail"
          v-model="email"
          hint="Најавете се со електронска пошта"
          required
          :error-messages="errors.collect('email')"
          v-validate="'required|email'"
          data-vv-name="email"
        ></v-text-field>
        <v-text-field
          label="Лозинка"
          v-model="pass"
          hint="Внесете ја лозинката"
          required
          :error-messages="errors.collect('pass')"
          v-validate="'required'"
          data-vv-name="pass"
          :append-icon="e1 ? 'visibility' : 'visibility_off'"
          :append-icon-cb="() => (e1 = !e1)"
          :type="e1 ? 'password' : 'text'"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="validateForm" :loading="$store.state.loading">Најава</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from "axios";
export default {
  /**
   *
   */
  data() {
    return {
      email: "",
      pass: "",

      errorMessage: "",
      e1: true // show/hide password
    };
  }, // data

  /**
   *
   */
  methods: {
    /**
     * Validate the form
     */
    validateForm(event) {
      this.$validator.validateAll().then(result => {
        if (result) this.login();
      });
    },

    /**
     * Try to login
     */
    login() {
      this.$store
        .dispatch("login", { email: this.email, pass: this.pass })
        .catch(e => {
          this.errorMessage = e;
        });
    }
  }, // methods

  /**
   *
   */
  computed: {
    /**
     * Store user changes
     */
    user() {
      return this.$store.state.user.user;
    }
  }, // computed

  /**
   *
   */
  watch: {
    email() {
      this.errorMessage = "";
    },
    pass() {
      this.errorMessage = "";
    },

    /**
     * Watch if the user has successfully logged in
     */
    user(value) {
      if (value) {
        this.$router.replace("/");
      }
    }
  },

  /**
   *
   */
  beforeCreate() {
    if (this.$store.state.user.user) this.$router.replace("/"); // already logged in
  }
};
</script>