import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './plugins/custom'
import './plugins/validator'

Vue.config.productionTip = false


// Full application
if (document.getElementById('app')!== null)
{
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

// Just buy button with product price
import buyButton from '@/components/Public/ProductPrice';
import { VApp } from 'vuetify/lib'
// if (document.getElementById('buy-button')!== null)
// {
//   // The template is inside the buy-button div
//   new Vue({
//     //router,
//     store,
//     components: {buyButton, VApp},
//   }).$mount('#buy-button')
// }

const vues = document.querySelectorAll(".buy-button");
Array.prototype.forEach.call(vues, (el, index) => new Vue({
  el,
  store,
  components: {buyButton, VApp},
}))


// The cart
if (document.getElementById('cart')!== null)
{
  // The template is inside the buy-button div
  new Vue({
    store,
    components: {VApp},
  }).$mount('#cart')
}