import { render, staticRenderFns } from "./Coupon.vue?vue&type=template&id=46f16d4f&"
import script from "./Coupon.vue?vue&type=script&lang=js&"
export * from "./Coupon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/data/projects/winix/winix_shop/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib'
installComponents(component, {VTextField})
