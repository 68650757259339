<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h4>Листа на платени нарачки</h4>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Пребарај"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-card-title>

      <a class="filter-zaisporaka" @click="search='Za isporaka'">Za isporaka</a>

      <v-data-table
        disable-initial-sort
        :headers="headers"
        :items="items"
        :search="search"
        :rows-per-page-items="[10,25,50,{'text':'All','value':-1}]"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.created | moment('DD.MM.YYYY') }}</td>
          
          <td>{{ props.item.price}}</td>
          <td>{{ props.item.discount}}</td>
          <td>{{ props.item.total}}</td>

          <td>{{ props.item.name}}</td>
          <td>{{ props.item.email}}</td>
          <td>{{ props.item.tel}}</td>
          <td><span class="small">{{ props.item.description}}</span></td>
          <td>{{ props.item.sentString}}</td>

          <td class="text-xs-right">
            <v-btn flat small fab @click="edit(props.item)">
              <v-icon class="icon-button">edit</v-icon>
            </v-btn>
          </td>
        </template>
        <div slot="no-results">Пребарувањето за "{{ search }}" не врати никакви резултати</div>
      </v-data-table>

      <!-- dialog za dodavane na nov zakon-->
      <v-dialog v-model="openEditForm" max-width="600px" lazy persistent->
        <v-card>
          <v-card-title>
            <!-- distroy the component using v-if on close, because of the validation problems -->
            <order-form
              v-if="openEditForm"
              :order="editObject"
              @close="openEditForm=false"
              @saved="saved"
            ></order-form>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>



<script>
import axios from "axios";
import OrderForm from "@/components/Admin/Form/OrderForm";

export default {
  data() {
    return {
      items: [],
      search: "Za isporaka",

      headers: [
        { text: "ID", value: "id" },
        { text: "Датум", value: "created" },

        { text: "Вкупно", value: "price" },
        { text: "Попуст (%)", value: "discount" },
        { text: "Наплатено", value: "total" },

        { text: "Нарачател", value: "name" },
        { text: "E-Mail", value: "email" },
        { text: "Тел.", value: "tel" },
        { text: "Забелешка", value: "description" },
        { text: "Status", value: "sentString" },
        {
          text: "",
          align: "right",
          sortable: false,
          value: ""
        }
      ],

      // edit
      openEditForm: false,
      editObject: null
    };
  }, // data

  methods: {
    /**
     * Load user list
     *
     */
    async load() {
      try {
        let response = await axios.get("/backend/member/admin/order/list");
        this.items = response.data.orders;
      } catch (err) {
        console.log(err);
        this.$store.commit("showSnackbar", err.message);
      }
    },

    /**
     * Open edit form and pass the object
     *
     */
    edit: function(order) {
      this.editObject = order;
      this.openEditForm = true;
    },

    /**
     * Called after the clen is saved
     */
    saved: function(payload) {
      // mergiraj gi novite izmenvi vo stariot objekt. stariot e prenesen po referenca
      if (payload.old.id) Object.assign(payload.old, payload.new);
      else this.items.push(payload.new);

      this.openEditForm = false;
    }
  },

  created() {
    this.load();
  },

  components: { OrderForm }
};
</script>

<style scoped>
.small {
  font-size: 10px;
}

.filter-zaisporaka {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
}
</style>