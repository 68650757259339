<template>
  <div>
    <v-card class="no-padding" v-if="!successPayment">
      <v-card-title primary-title class="headline grey lighten-2" style="position: relative;">
        Кошничка
        <div class="cart-subtitle">Не е потребна регистрација. Испораката е бесплатна.</div>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="$emit('close')" class="close-cart-icon">
          <i class="fa fa-times" aria-hidden="true"></i>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="error-payment" v-if="errorPayment">
          <p>
            Вашата трансакција беше неуспешна.
            <br>
            <br>Ве молиме проверете ги повторно податоците од картичката дали се точно внесени или обидете се со друга картичка.
          </p>
        </div>

        <!--
        <div class="description" v-if="!errorPayment">
          <ul>
            <li>Плаќањето е брзо и лесно, не е потребна регистрација.</li>
            <li>Испораката е бесплатна.</li>
            <li>За успешна нарачка ќе добиете потврда по E-Mail.</li>
          </ul>
        </div>
        -->

        <v-container grid-list-md>
          <v-layout row wrap class="elevation-2 region-product-grid">
            <product-grid
              :coupon="coupon"
              :products="[this.product]"
              @total="total=$event.total; cartItems=$event.items;"
            ></product-grid>

            <v-flex xs12 md5>
              <coupon @coupon="coupon=$event"></coupon>
            </v-flex>
            <v-flex xs12 md7>
              <div class="display">
                За наплата:
                <span class="total-price">{{total | numeral("0,0").replace(/,/g, "&#8239;")}}</span>
                <span class="den">ден.</span>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>

              <p class="shipping-headline">Податоци за испораката</p>

              <v-text-field
                label="E-Mail *"
                hint="На оваа адреса ќе ви биде пратена потврда."
                title="На оваа адреса ќе ви биде пратена потврда."
                v-model="email"
                v-validate="'required|email|max:255'"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md8>
              <v-text-field
                label="Име и презиме *"
                hint="Лице за контакт"
                title="Лице за контакт."
                v-model="name"
                v-validate="'required|max:255'"
                :error-messages="errors.collect('name')"
                data-vv-name="name"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                label="Телефон *"
                hint="Телефон за контакт"
                title="Телефон за контакт."
                v-model="tel"
                v-validate="'required|max:255'"
                :error-messages="errors.collect('tel')"
                data-vv-name="tel"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md8>
              <v-text-field
                label="Адреса *"
                hint="На која адреса да се достави"
                title="На која адреса да се достави."
                v-model="address"
                v-validate="'required|max:255'"
                :error-messages="errors.collect('address')"
                data-vv-name="address"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                label="Град *"
                title="Град за достава."
                hint
                v-model="city"
                v-validate="'required|max:255'"
                :error-messages="errors.collect('city')"
                data-vv-name="city"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                v-model="note"
                label="Дополнително инфо"
                title="Дополнително инфо. Внесете слободен текст, доколку сакате да оставите дополнителни барања за испораката."
                hint="Доколку имате дополнителни барања, може да ги наведете овде."
                auto-grow
                rows="1"
                :counter="255"
                v-validate="'max:255'"
                :error-messages="errors.collect('note')"
                data-vv-name="note"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <div class="terms">
        <a
          href="https://www.winixmk.mk/terms-of-use/"
          target="_blank"
          title="Прочитејте ги условите за користење."
        >Услови за користење</a> и
        <a
          href="https://www.winixmk.mk/privatnost/"
          target="_blank"
          title="Прочитејте ја поликиката за приватност."
        >политика за приватност</a>
      </div>

      <v-card-actions>
        <v-btn
          flat
          class="grey--text"
          @click="$emit('close')"
          title="Затвори ја кошничката. Податоците нема да бидат зачувани."
        >Откажи</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="$store.state.loading"
          @click="pay"
          title="Извршете плаќање со електронска картичка. Плаќањето се врши преку безбеден и енкриптиран систем CaSys и ние НЕ ги превземаме податоците за Вашата картичка. Откако ќе извршите плаќање ќе добиете потврден Е-Мајл и ние производите ќе ви ги доставиме бесплатно. Не го затворајте прозорот додека трае наплатата!"
        >Плати со картичка</v-btn>
      </v-card-actions>

      <v-divider></v-divider>

      <v-card-text>
        <span class="pay-disclaimer">
          WinixMk не превзема и не чува никакви податоци за вашата картичка.
          Наплатата се врши преку интернационален,
          безбеден и енкриптиран систем за наплата CaSys - https://cpay.com.mk.
        </span>
      </v-card-text>

      <cpaypopup ref="cpay" @error="cpayError" @success="cpaySuccess"></cpaypopup>
    </v-card>

    <!-- POTVRDA DEKA NARACKATA E PLATENA -->
    <v-card class="no-padding" v-if="successPayment">
      <v-card-title primary-title class="headline grey lighten-2">{{product.name}}</v-card-title>
      <v-card-text>
        
        
        <div class="success-payment">
          <h2>Успешна нарачка.</h2>
          <p>
            Вашата нарачка е успешно платена.
            <br>За потврда ќе добиете E-Mail на
            <b>{{email}}</b>
          </p>
          <p class="checkspam">Доколку не ви стигне потврда за нарачка, проверете во спам.</p>
        </div>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat class="grey--text" @click="$emit('close')">Затвори</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import cpaypopup from "./CpayPopup";
import coupon from "@/components/Public/Partial/Coupon.vue";
import productGrid from "./CartProductGrid.vue";

export default {
  /**
   * Provide this validator to child components,
   * so they can be also validated
   */
  provide() {
    return { parentValidator: this.$validator };
  },

  /**
   *
   */
  props: {
    /**
     * Product code from product table
     */
    product: {
      required: true
    }
  },

  /**
   * Data
   *
   */
  data() {
    return {
      email: "",
      name: "",
      address: "",
      city: "",
      tel: "",
      note: "",

      errorPayment: false,
      successPayment: false,

      coupon: null,
      total: "",

      cartItems: []
    };
  }, // data

  /**
   * Methods
   *
   */
  methods: {
    /**
     * Load the product
     */
    async getProduct() {
      try {
        // let response = await axios.get(process.env.API_ROOT + "/member/public/zakoni/list");
        // this.zakoni = response.data.items;
      } catch (error) {
        this.$store.commit("showSnackbar", error.message);
      }
    },

    /**
     * Validiraj i plati
     */
    pay() {
      this.$validator.validateAll().then(valid => {
        if (!valid) return;
        this.createOrder();
      });
    },

    /**
     * Kreiraj order i plati
     */
    createOrder() {
      this.errorPayment = false;
      // show the payment window first, in order to prevent browser blocking the popup
      this.$refs.cpay.show();

      let me = this;
      let response = axios.post("/backend/cart/paymenturl", {
        email: this.email,
        name: this.name,
        address: this.address,
        city: this.city,
        tel: this.tel,
        note: this.note,
        coupon: this.coupon,
        items: this.cartItems
      }).then(function(response){
        // Load this url in the popup window
        me.$refs.cpay.setUrl(response.data.url);
      });
    },

    /**
     * Payment is successfull
     *
     */
    cpaySuccess() {
      this.successPayment = true;
    },

    /**
     * Payment is not successfull
     *
     */
    cpayError() {
      this.errorPayment = true;
      
      var myDiv = document.getElementsByClassName('v-dialog');
      myDiv[0].scrollTop = 0;

    },

    /**
     * Load user data from storage and fill shipping fields
     * 
     */
    loadFromStorage() {
      let data = null;
      var retrievedObject = localStorage.getItem('shippingdata');
      if (retrievedObject)
        data = JSON.parse(retrievedObject);
      if (!data)
        return;

      this.email = data.email,
      this.name = data.name,
      this.address = data.address,
      this.tel = data.tel,
      this.city = data.city,
      this.note = data.note
    },

    /**
     * save user shipping fields in storage
     * 
     */
    setInStorate() {
      var data = {
        email: this.email,
        name: this.name,
        address: this.address,
        tel: this.tel,
        city: this.city,
        note: this.note
      }
      localStorage.setItem('shippingdata', JSON.stringify(data));
    }
  },

  watch: {
    email() {
      this.setInStorate();
    },
    name() {
      this.setInStorate();
    },
    address() {
      this.setInStorate();
    },
    tel() {
      this.setInStorate();
    },
    city() {
      this.setInStorate();
    },
    note() {
      this.setInStorate();
    },
  },

  mounted() {
    this.loadFromStorage();
  },

  components: { cpaypopup, coupon, productGrid }
};
</script>

<style scoped>
.region-product-grid {
  padding: 10px 10px 0 10px;
}

.no-padding .v-card__text {
  padding: 0;
}

.pay-disclaimer {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 10px;
  margin: 10px 20px 0 20px;
  display: block;
  line-height: 13px;
}

.display {
  text-align: right;
  font-size: 20px;
  padding-top: 10px;
}

.display span {
  display: inline-block;
}

.display .price,
.display .total-price {
  font-size: 30px;
  /* word-spacing: 0.5em; */
}

.display .total-price {
  font-weight: bold;
  color: #1e9e6b;
}

.display .den {
  margin-left: 5px;
}

.description {
  margin: 15px 24px 0 24px;
  padding: 10px;
  background-color: #ededed;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}

.checkspam {
  font-size: 11px;
}

.terms {
  text-align: right;
  font-size: 12px;
  margin-right: 15px;
}

.v-card__title {
  padding-top: 5px;
}
.cart-subtitle {
  font-size: 12px;
  position: absolute;
  bottom: -3px;
}

.shipping-headline {
  margin-bottom: 0;
  margin-top: 2em;
  font-size: 12px;
  font-weight: bold;
  color: rgba(0,0,0,0.5);
}


.success-payment {
  background-color: #1e9e6b;
  color: white;
  padding: 20px;
}
.success-payment h2,
.success-payment p
 {
  color: white;
}

.error-payment {
  background-color: #ff5252;
  color: white;
  padding: 20px;
}
.error-payment p{
  color: white;
}
</style>