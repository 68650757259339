<template>
  <div>
    <div v-if="product">
      <!-- CENA -->
      <div v-if="product.discount" class="discount-container">
        <!-- Cena so popust -->
        <table class="discount">
          <tr>
            <td>Цена:</td>
            <td>{{product.price}}</td>
            <td>&nbsp;ден.</td>
          </tr>
          <tr>
            <td>Попуст:</td>
            <td>{{product.discount}}</td>
            <td>&nbsp;%</td>
          </tr>
          <tr>
            <td>
              <span class="price-label">Цена со попуст:</span>
            </td>
            <td>
              <span class="price">{{priceWithDiscount | numeral("0,0").replace(/,/g, "&#8239;")}}</span>
            </td>
            <td>&nbsp;ден.</td>
          </tr>
        </table>
      </div>
      <span v-else>
        <!-- Cena bez popust -->
        <span class="price-label">Цена:</span>
        <span class="price">&nbsp;{{product.price | numeral("0,0").replace(/,/g, "&#8239;") }} ден.</span>
      </span>

      <!-- Kopce za kupi dokolku ima zaliha -->
      <cart-button :product="product" v-if="product.available"></cart-button>

      <!-- tekst koga nema zaliha -->
      <span
        v-if="!product.available"
        class="availability"
        :class="{'has-discount': product.discount}"
      >
        <span>&nbsp;X </span>
        <span v-if="product.availabilityDescription">&nbsp;{{product.availabilityDescription}}</span>
        <span v-else>&nbsp;нема на залиха.</span>
      </span>
    </div>

    <!-- Progress bar dodeka se cita vrednosta -->
    <v-progress-linear :indeterminate="true" v-if="$store.state.loading" class="progress-button"></v-progress-linear>
  </div>
</template>

<script>
import axios from "axios";
import cartButton from "@/components/Public/Cart/CartButton";
export default {
  /**
   *
   */
  props: {
    /**
     * Product code from product table
     */
    code: {
      required: true
    }
  },

  /**
   * Data
   *
   */
  data() {
    return {
      product: null
    };
  }, // data

  /**
   * Methods
   *
   */
  methods: {
    /**
     * Load the product
     */
    async getProduct() {
      try {
        let response = await axios.get("/backend/cart/product/" + this.code);
        this.product = response.data.product;
      } catch (error) {
        //this.$store.commit("showSnackbar", error.message);
        console.log(error);
      }
    }
  },
  
  computed: {
    /**
     * Cena so popust
     */
    priceWithDiscount() {
      if (!this.product.discount) return null;

      return Math.floor(
        this.product.price - (this.product.price * this.product.discount) / 100
      );
    }
  },

  /**
   * Mounted
   */
  mounted() {
    this.getProduct();
  },

  components: { cartButton }
};
</script>

<style scoped>
.availability {
  font-size: 16px !important;
  display: inline-block;
}
.availability.has-discount {
  padding-bottom: 4px;
}
.availability span {
  /* font-size: 1.1em; */
  color: red !important;
  /* font-weight: bold; */
}
.availability span {
  color: rgba(0, 0, 0, 0.54);
}

.price-label {
  font-weight: bold;
  font-size: 15px;
  color: rgb(86, 86, 86);
}

.price {
  font-size: 18px;
  color: rgb(0, 128, 0);
  font-weight: bold;
}

.discount-container {
  display: inline-block;
  vertical-align: bottom;
}
.discount {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}
.discount-percentage {
  font-weight: bold;
}

.discount tr td:first-child,
.discount tr td:nth-child(3) {
  text-align: left;
}
.discount tr td:nth-child(2) {
  text-align: right;
}

/* .progress-button {
  width: 200px;
} */
</style>