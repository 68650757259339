<template>
  <v-container>
    <v-layout justify-center>
      <v-flex sm12 lg5>
        <v-card>
          <v-tabs>
            <v-tab key="login">Најава</v-tab>
            <v-tabs-items>
              <v-tab-item key="login">
                <login></login>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import Login from "./Partial/LoginForm";

export default {
  data() {
    return {};
  },

  /**
   *
   */
  beforeCreate() {
    if (this.$store.state.user.user) this.$router.replace("/"); // already logged in
  },

  components: { Login }
};
</script>