<template>
  <v-dialog v-if="showLocal" v-model="showLocal" width="600">
    <v-card>
      <v-progress-linear
        class="main-progress"
        :indeterminate="true"
        height="3"
        :active="$store.state.loading"
      ></v-progress-linear>
      <v-toolbar card color="grey lighten-2">
        <v-toolbar-title>Додади продукт во кошничката</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="showLocal = false" class="close-cart-icon">
          <i class="fa fa-times" aria-hidden="true"></i>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list two-line subheader>
          <template v-for="(item, index) in items">
            <v-list-tile :key="item.id" @click="addItem(item)" v-if="item.available">
              <v-list-tile-content>
                <v-list-tile-title>{{item.name}}</v-list-tile-title>
                <v-list-tile-sub-title>Цена: {{item.price}} ден. 
                  <span v-if="item.discount"> - {{item.discount}} % попуст = {{item.price - item.price * item.discount / 100}} ден.</span>

                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider v-if="index + 1 < items.length" :key="item.id + 'divider'"></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

/**
 * Emit 
 * - add
 */
export default {
  props: {
    /**
     * To show or hide the dialog
     */
    show: {
      required: false,
      default: false
    }
  },

  data() {
    return {
      showLocal: this.show,
      items: []
    };
  },

  methods: {
    /**
     * Load data form server
     */
    async load() {
      let response = await axios.get("/backend/cart/product/list/all");
      this.items = response.data.products;
    },

    /**
     * Show the dialog
     */
    showIt() {
      this.showLocal = true;
    },

    /**
     * Trigger add new item
     */
    addItem(item) {
      this.$emit("add", item);
      this.showLocal = false;
    }
  },

  /**
   * Watch for changes
   */
  watch: {
    /**
     * Trigger the dialog when the prop changes
     */
    show(val) {
      this.showLocal = val;
    },

    showLocal() {
      if (this.items.length == 0) this.load();
    }
  },

  /**
   * The component is created
   */
  mounted() {}
};
</script>

<style>
</style>