import Vue from 'vue'
import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate'
//import axios from 'axios';


Vue.use(VeeValidate)

/**
 * Translate validator messages
 * 
 */
Validator.localize('en', {
    messages: {
        required: 'Полето е задолжително',
        email: 'E-Mail адресата не е валидна',
        between: (field, [min, max]) => `Валидно од ${min} до ${max}`,
        alpha_num: "Валидни само броеви > 0"
    },
});