<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h4>Листа на produkti</h4>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Пребарај"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn fab small @click="edit({})">+</v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :rows-per-page-items="[25,50,{'text':'All','value':-1}]"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.code }}</td>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.price }}</td>
          <td>{{ props.item.discount }}</td>
          <td :class="{notavailable: !props.item.available }">
            {{ props.item.available }}
            </td>
          <td>{{ props.item.availabilityDescription }}</td>
          <td class="text-xs-right">
            <v-btn flat small fab @click="edit(props.item)">
              <v-icon class="icon-button">edit</v-icon>
            </v-btn>
          </td>
        </template>
        <div
          slot="no-results"
        >Пребарувањето за "{{ search }}" не врати никакви резултати</div>
      </v-data-table>

      <!-- dialog za dodavane na nov zakon-->
      <v-dialog v-model="openEditForm" max-width="500px" lazy>
        <v-card>
          <v-card-title>
            <!-- distroy the component using v-if on close, because of the validation problems -->
            <product-form
              v-if="openEditForm"
              :product="editObject"
              @close="openEditForm=false"
              @saved="saved"
            ></product-form>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import ProductForm from "@/components/Admin/Form/ProductForm";

export default {
  data() {
    return {
      items: [],
      search: "",

      headers: [
        { text: "ID", value: "id" },
        { text: "Шифра", value: "code" },
        { text: "Име", value: "name" },
        { text: "Цена", value: "price" },
        { text: "Попуст (%)", value: "discount" },
        { text: "Достапен", value: "available" },
        { text: "Достапност опис", value: "availability_description" },
        {
          text: "",
          align: "right",
          sortable: false,
          value: ""
        }
      ],
      // edit
      openEditForm: false,
      editObject: null
    };
  }, // data

  methods: {
    /**
     * Load user list
     *
     */
    async load() {
      try {
        let response = await axios.get("/backend/member/admin/product/list");
        this.items = response.data.products;
      } catch (err) {
        console.log(err);
        this.$store.commit("showSnackbar", err.message);
      }
    },

    /**
     * Open edit form and pass the object
     *
     */
    edit: function(gift) {
      this.editObject = gift;
      this.openEditForm = true;
    },

    /**
     * Called after the clen is saved
     */
    saved: function(payload) {
      // mergiraj gi novite izmenvi vo stariot objekt. stariot e prenesen po referenca
      if (payload.old.id) Object.assign(payload.old, payload.new);
      else this.items.push(payload.new);

      this.openEditForm = false;
    }
  },

  /**
   *
   */
  created() {
    this.load();
  },

  components: { ProductForm }
};
</script>

<style scoped>
  .notavailable {
    color: red;
  }
</style>