<template>
  <v-container>
    <v-layout
      text-xs-center
      wrap
    >
      <v-flex xs12>
       
        Test
        <product-price code="test"></product-price>
      </v-flex>

    </v-layout>
  </v-container>
</template>

<script>

  import productPrice from '@/components/Public/ProductPrice';

  export default {
    data: () => ({
    }),

    components: {productPrice}
  }
</script>

<style>

</style>
