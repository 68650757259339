import store from '../store/store'
import axios from "axios"
import router from '../router'
import Vue from 'vue'

axios.interceptors.request.use(
    function (config) {
        store.commit('setLoading', true)
        return config;
    },
    function (error) {
        store.commit('setLoading', false)
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    function (response) {
        store.commit('setLoading', false)
        return response;
    },
    function (error) {
        store.commit('setLoading', false)
        
        // check if the resource is not allowed, logout.
        if (error.response && error.response.status == 401)
            store.dispatch('logout') // will trigger wath App.user to go to login page
                    
        return Promise.reject(error);
    }
);


// check if the user is logged in
// this must be above "new Vue"
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if(requiresAuth) {
        if(!store.state.user.user)
            next('/login');
        else
            next();    
    } else {
        next();
    }
});


// library for formatting date
// https://github.com/brockpetrie/vue-moment
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
    moment,
})


import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller);