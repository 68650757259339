<template></template>
<script>
/**
 * Methods
 * - show()
 * - setUrl(url)
 *
 * Emits
 * - error
 * - success
 */
export default {
  /**
   *
   */
  props: {
    /**
     * Width of the popup
     */
    width: {
      required: false,
      default: 600
    },

    /**
     * Height of the popup
     */
    height: {
      required: false,
      default: 600
    }
  },

  /**
   * Data
   *
   */
  data() {
    return {
      popup: null
    };
  }, // data

  /**
   * Methods
   *
   */
  methods: {
    /**
     * Show a popup window
     */
    show() {
      if (this.popup) this.popup.close();

      var left = screen.width / 2 - this.width / 2;
      var top = screen.height / 2 - this.height / 2;
      this.popup = window.open(
        "",
        "_blank",
        "toolbar=no, location=no, directories=no, personalbar=no, minimizable=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          this.width +
          ", height=" +
          this.height +
          ", top=" +
          top +
          ", left=" +
          left
      );
      this.popup.document.write("Loading ...");
    },

    /**
     * Set the url
     */
    setUrl(url) {
      this.popup.location.href = url;

      window.removeEventListener("message", this.receiveMessage, false);
      window.addEventListener("message", this.receiveMessage, false);
    },

    /**
     * When user sumbmits credentials in the window popup that is displayed by linkedinlogin,
     * it will be redirected to /de/linkedin/redirectpopup in this same window popup.
     * That page will send window message to the parent window which is this one with the received data
     */
    receiveMessage(event) {
      if (event.origin !== window.location.origin) return;

      if ((event.data.status === "error" && event.data.from === "cpay")) {
        this.$emit("error", event.data);
        this.popup.close();
      } else if (
        (event.data.status === "success" && event.data.from === "cpay")
      ) {
        this.$emit("success", event.data);
        this.popup.close();
      }
    }
  }
};
</script>