import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

import Login from '@/components/Admin/Login.vue'
import Orders from '@/components/Admin/Orders.vue'
import Products from '@/components/Admin/Products.vue'
import Coupons from '@/components/Admin/Coupons.vue'

Vue.use(Router)

export default new Router({

  base: '/frontend',
  mode: 'history',

  routes: [


    // ADMIN links
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/orders',
      name: 'Orders',
      component: Orders,
      meta: { requiresAuth: true }
    },
    {
      path: '/products',
      name: 'Products',
      component: Products,
      meta: { requiresAuth: true }
    },
    {
      path: '/coupons',
      name: 'Coupons',
      component: Coupons,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { requiresAuth: false }
    },

    {
      path: '*',
      name: 'NotFound',
      component: { template: '<h1 class="ml-5">Page not found</h1>' },
      meta: { requiresAuth: false }
    },
  ]
})
