<template>
  <v-container fluid grid-list-md>
    <v-form>
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            label="Назив *"
            v-model="productLocal.name"
            hint="Име на продуктот"
            :error-messages="errors.collect('name')"
            v-validate="'required'"
            data-vv-name="name"
          ></v-text-field>
          <v-text-field
            label="Шифра *"
            v-model="productLocal.code"
            hint="Шифра на протуктот"
            :error-messages="errors.collect('code')"
            v-validate="'required'"
            data-vv-name="code"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md4>
          <v-text-field
            label="Цена *"
            v-model="productLocal.price"
            hint="Редовна цена на продуктот"
            type="number"
            :error-messages="errors.collect('price')"
            v-validate="'required'"
            data-vv-name="price"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md4>
          <v-text-field
            label="Попуст"
            v-model="productLocal.discount"
            hint="Попуст на продуктот во %"
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md4>
          <v-text-field
            label="Макс. попуст"
            v-model="productLocal.maxDiscount"
            hint="Макс. попуст во %"
            type="number"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-switch label="На лагер" v-model="productLocal.available"></v-switch>
          <v-text-field
            label="Достапен текст"
            v-model="productLocal.availabilityDescription"
            hint="Опис кога ќе биде достапен"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-card-actions>
            <v-btn color="primary" flat @click="close">Откажи</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="hasNotValidField"
              @click="save"
              :loading="$store.state.loading"
            >Запамти</v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: {
    product: {
      required: true,
      default: null
    }
  },

  data() {
    return {
      valid: false,
      productLocal: Object.assign({ id: 0 }, this.product)
    };
  }, // data

  methods: {
    /**
     * Save the changes
     *
     */
    save: async function() {
      try {
        let response = await axios.post(
          "/backend/member/admin/product/update/" + this.productLocal.id,
          this.productLocal
        );
        this.$emit("saved", { old: this.product, new: response.data });
        this.$store.commit(
          "showSnackbar",
          "Podatocite za podarokot se zapamteni"
        );
      } catch (err) {
        console.log(err);
        this.$store.commit("showSnackbar", err.message);
      }
    },

    /**
     * Emit close signal
     *
     */
    close() {
      this.$emit("close");
    }
  },

  computed: {
    /**
     * Check the form using vee validate,
     * if there are any fields that are not valid.
     *
     * return true if there are not valid fields
     */
    hasNotValidField() {
      return Object.keys(this.fields).some(
        key => this.fields[key].valid == false
      );
    }
  }
};
</script>