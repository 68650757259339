//import shop from '../../api/ordersApi'
import axios from 'axios'
import * as Cookies from 'js-cookie'

const state = {

    /**
     * User object
     *  {
     *    role:  'admin',
     *    name:  'darko petreski', 
     *    email: email
     *  }
     */
    user: null,
}

/**
 * GETTERS
 * 
 */
const getters = {
    /**
     * 
     * @param {type} state
     * @param {type} getters
     * @returns {unresolved}
     */
}

// actions
const actions = {

    /**
     * Called with this.$store.dispatch('login', {email:'', pass:''})
     * 
     */
    login({ commit }, userData) {

        return new Promise((resolve, reject) => {


            const form = new FormData();
            form.append('_username', userData.email)
            form.append('_password', userData.pass)

            axios.post('/backend/member/login_check', form)
                .then(function (response) {

                    commit('setUser', response.data)
                    resolve('');
                }.bind(this))
                .catch(function (error) {
                    if (error.response.status == 401) {
                        reject('Невалидно корисничко име или лозинка')
                    }
                    else {
                        reject('Error. Cannot login')
                    }
                }.bind(this));
        })// promise
    },

    /**
     * Log out user
     * 
     * @param {type} commit
     * @returns {undefined}
     */
    logout({ commit, state }) {

        if (state.user !== null)
            axios.get('/backend/member/logout', {})

        commit('setUser', null);
        Cookies.remove('vuex')
    },
}

// mutations
const mutations = {

    /**
     * Mutate user object
     * 
     */
    setUser(state, user) {
        state.user = user
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}