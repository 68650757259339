import { render, staticRenderFns } from "./Cart.vue?vue&type=template&id=499a588c&scoped=true&"
import script from "./Cart.vue?vue&type=script&lang=js&"
export * from "./Cart.vue?vue&type=script&lang=js&"
import style0 from "./Cart.vue?vue&type=style&index=0&id=499a588c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "499a588c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/data/projects/winix/winix_shop/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VContainer,VDivider,VFlex,VLayout,VSpacer,VTextField,VTextarea})
