<template>
  <v-text-field
    label="Купон за попуст"
    title="Доколку имате купон за попуст, внесете го, за да добиете попуст на целата кошничка."
    v-model="code"
    v-validate="'coupon'"
    :error-messages="errors.collect('code')"
    data-vv-name="code"
    :loading="$store.state.loading"
    data-vv-delay="500"
  ></v-text-field>
</template>

<script>
import axios from "axios";
import { Validator } from "vee-validate";

export default {
  /**
   * Inject parent validator into this component, so it can be validated
   * within the parent
   */

  // To not bind this component with the parent one!
  // Because this will validate with ajax on submit, and in firefox will prevent
  // opening the CPay payment form in popup. 
  // inject: {
  //   $validator: "parentValidator"
  // },

  /**
   * Data
   *
   */
  data() {
    return {
      code: "",
      timeout: null
    };
  }, // data

  /**
   * Methods
   *
   */
  methods: {
    /**
     * Load the product
     */
    couponValidator() {
      let me = this;
      return new Promise(function(resolve) {
        let response = axios
          .get("/backend/cart/coupon/" + me.code)
          .then(function(response) {
            if (response.data.coupon != null) {
              me.$emit("coupon", response.data.coupon);
              return resolve({
                valid: true
              });
            } else {
              me.$emit("coupon", null);
              return resolve({
                valid: false,
                data: {
                  message: `Кодот е невалиден или истечен`
                }
              });
            }
          });
      });
    }
  },

  /**
   *
   */
  watch: {
    /**
     * Watch for code change
     */
    code(val) {
      if (!val) this.$emit("coupon", null);
    }
  },
  
  mounted() {
    Validator.extend("coupon", {
      validate: this.couponValidator,
      getMessage: (field, params, data) => data.message
    });
  }
};
</script>