import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'

Vue.use(Vuex)

import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

export default new Vuex.Store({

  plugins: [

    /**
     * Plugin for storing some of the vuex state in cookie
     * 
     */
    createPersistedState({
      paths: [
        // // save only this states automatically in the cookie
        'user.user', // module.variable in the module state
      ],
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { secure: false }),
        removeItem: key => Cookies.remove(key)
      }
    })
  ],

  state: {
    loading: false, // true when axios is working

    // stackbar messaging
    snackbar: false,
    snackbarText: '',
  },

  /**
     * Changes the state. 
     * Muttations are called with commit, example this.$store.commit('setLoading', true)
     * Mutations Must Be Synchronous (if you need async, use actions with dispatch)
     * 
     * Usage from the vue components:
     * this.$store.commit('setLoading', true)
     * 
     */
  mutations: {

    /**
     * 
     */
    showSnackbar(state, message) {
      state.snackbar = true;
      state.snackbarText = message;
      //console.log(message)
    },

    /**
     * Called automatically when axios is working
     */
    setLoading(state, loading) {
      state.loading = loading
    },
  },

  /**
     * Actions are async methods that can be called from the components.
     * Actions internally calls the mutations by commit
     * Actions are used like this:
     * 
     * Usage from the vue components:
     * this.$store.dispatch('search', {})
     */
  actions: {

  },


  /**
 * List of additional modules 
 * 
 */
  modules: {
    user,
  },

})
